import React from 'react';
import { useSelector } from 'react-redux';
import {
  useRouteMatch,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';

import ApplicationCheck from 'redux/modules/ApplicationCheck/wrapper';
import PageTemplate from 'modules/layout/PageTemplate';
import PageHeading from 'components/modules/Shared/PageHeading';
import SectionCentered from 'modules/layout/SectionCentered';
import BoilerCoverContainer from 'redux/modules/BoilerCover/container';
import BillIncomeProtector from 'redux/modules/BillIncomeProtector/container';
import HomeInsurance from 'redux/modules/HomeInsurance/container';
import { SectionTracker } from 'components/modules/Shared/ProgressTracker';
import { InsuranceBoiler, InsuranceBIP } from 'redux/modules/Routes/constants';
import { isEligibleForBip } from 'redux/modules/App/transitions/api';
import { FeatureFlagsApi } from 'redux/modules/FeatureFlags/api';
import { FLAGS } from 'redux/modules/FeatureFlags/constants';
import { Alert } from '@material-ui/lab';
import { Typography } from '@material-ui/core';

import useStyles from './styles';

const Insurance = (props) => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const { pathname: fullPath } = useLocation();
  const eligibleForBip = useSelector(isEligibleForBip);
  const isHomeInsuranceEnabled = useSelector(
    FeatureFlagsApi.getFlagSelector(FLAGS.INSURANCE_HOME_INSURANCE_ENABLED)
  );

  const protectorLabel = 'Income protector';

  const pathMap = [];

  pathMap.push({
    path: InsuranceBoiler,
    label: 'Boiler & Home cover',
  });

  if (eligibleForBip) {
    pathMap.push({
      path: InsuranceBIP,
      label: protectorLabel,
    });
  }

  const currentIndex = pathMap.findIndex((p) => p.path === fullPath);
  const current = pathMap[currentIndex];
  const next = pathMap[currentIndex + 1] || null;

  return (
    <PageTemplate
      helmetTitle={`Utility Warehouse - Join the club - Your insurance`}
      headerProps={props}
      footerProps={props}
    >
      <PageHeading title="Your insurance" />
      <ApplicationCheck>
        {current && (
          <SectionCentered newLayout>
            <nav className={classes.progressContainer}>
              <SectionTracker
                sections={pathMap.length}
                active={currentIndex + 1}
                current={current.label}
                next={next?.label}
                classes={{ root: classes.progress }}
              />
              <Alert
                variant="filled"
                severity="info"
                className={classes.message}
              >
                <Typography className={classes.messageContent}>
                  This product is regulated by the FCA and sold on a non-advised
                  basis. This means Partners will not discuss this product with
                  you. If you have any questions contact UW on 0333 777 0777.
                </Typography>
              </Alert>
            </nav>
          </SectionCentered>
        )}
        <Switch>
          <Route path={`${path}/boiler`} component={BoilerCoverContainer} />
          <Route
            path={`${path}/billincomeprotector`}
            component={BillIncomeProtector}
          />
          {isHomeInsuranceEnabled && (
            <Route path={`${path}/home-insurance`} component={HomeInsurance} />
          )}
          <Route path={path}>
            <Redirect to={{ pathname: `${path}/boiler` }} />
          </Route>
        </Switch>
      </ApplicationCheck>
    </PageTemplate>
  );
};

export default Insurance;
